<template>
  <v-card>
    <v-card-text v-if="tiene_permiso">
      <app-tab-component :items="tabs" v-if="tabs.length > 0">
        <template v-slot:[`contenido`]="{ item }">
          <component :is="item.component"></component>
        </template>
      </app-tab-component>
    </v-card-text>
  </v-card>
</template>
<script>
import envioDocumentosProveedor from "./envioDocumentosProveedor.vue";
import ReiniciosSubasta from "./ReiniciosSubasta.vue";
import Adjudicacion from "./Adjudicacion.vue";
import SN from "./GestionSolicitudesNecesidad/GestionSN.vue";
import HabilitarProcesosLibres from "./HabilitarProcesosLibres.vue";
import modificarEstadoProceso from "./modificarEstadoProceso.vue";
import EliminarEtapasContrato from "./EliminarEtapasContrato/EliminarEtapasContratos.vue";
import ListadoSolicitudesEspecificas from "./EliminarEspecificas/ListadoSolicitudesEspecificas.vue";
import ModificarEtapasRecursoRevision from "./RecursoRevision/ModificarEtapasRecursoRevision.vue";
import ModificarEtapasPipGeneral from "./PipGeneral/ModificarEtapasPipGeneral.vue";
import ModificarDocumentosProceso from "./DocumentosProceso/ModificarDocumentosProceso.vue";
import ConfiguracionLotesProceso from "./ConfiguracionLotes/ConfiguracionLotesProceso.vue";
import tabModificacionesProceso from "./tabModificacionesProceso.vue";
import { mapState } from "vuex";

export default {
  name: "ModificacionesProcesocompra",
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData"]),
    tiene_permiso() {
      return this.haveRole("ROLE_MODIFICAR_PROCESO");
    },
    tiene_permiso_modificacion_subasta() {
      return this.haveRole("ROLE_MODIFICAR_PROCESO_SUBASTA");
    },
    tiene_permiso_modificacion_adjudicacion() {
      return this.haveRole("ROLE_MODIFICAR_PROCESO_ADJUDICACION");
    },
    tiene_permiso_modificacion_sn() {
      return this.haveRole("ROLE_MODIFICAR_PROCESO_SN");
    },
    tiene_permiso_habilitar_procesos_libres() {
      return this.haveRole("ROLE_MODIFICAR_PROCESO_HABILITAR_PROCESO_LIBRE");
    },
    tiene_permiso_modificar_seguimiento() {
      return this.haveRole("ROLE_MODIFICAR_PROCESO_MODIFICAR_SEGUIMIENTO");
    },
    tiene_permiso_eliminar_etapas_contrato() {
      return this.haveRole("ROLE_HERRAMIENTA_ELIMINAR_ETAPAS_CONTRATO");
    },
    tiene_permiso_eliminar_especificas() {
      return this.haveRole("ROLE_MODIFICAR_PROCESO_ELIMINAR_ESPECIFICAS");
    },
    tiene_permiso_modificar_recurso_revision() {
      return this.haveRole("ROLE_MODIFICAR_ETAPAS_RECURSO_REVISION");
    },
    tiene_permiso_modificar_pip_general() {
      return this.haveRole("ROLE_MODIFICAR_PIP");
    },
    tiene_permiso_modificar_documentos_proceso() {
      return this.haveRole("ROLE_MODIFICAR_DOCUMENTOS_PROCESO");
    },
    esRolConfigurarLotes() {
      return this.haveRoles(["ROLE_CONFIGURACION_LOTES_DINAC"]);
    },
    esRolLecturaConfigurarLotes() {
      return this.haveRoles(["ROLE_LECTURA_CONFIGURACION_LOTES_DINAC"]);
    },
    esRolAdministrador() {
      return this.haveRoles(["ROLE_VISUALIZAR_MODIFICACIONES"]);
    },
    tabs() {
      const tabs = [];

      if (this.esRolAdministrador) {
        tabs.push({
          nombre: "Visualizar modificaciones",
          component: tabModificacionesProceso,
        });
      }

      tabs.push({
        nombre: "Envío de documentos a proveedor",
        component: envioDocumentosProveedor,
      });

      if (
        this.forma_contratacion === 23 &&
        this.tiene_permiso_modificacion_subasta
      ) {
        tabs.push({
          nombre: "Subasta",
          component: ReiniciosSubasta,
        });
      }
      if (this.tiene_permiso_modificacion_adjudicacion) {
        tabs.push({
          nombre: "Adjudicación",
          component: Adjudicacion,
        });
      }
      if (this.tiene_permiso_modificacion_sn) {
        tabs.push({
          nombre: "Solicitudes de Necesidad",
          component: SN,
        });
      }
      if (this.tiene_permiso_habilitar_procesos_libres) {
        tabs.push({
          nombre: "Cierre de contratos",
          component: HabilitarProcesosLibres,
        });
      }
      if (this.tiene_permiso_modificar_seguimiento) {
        tabs.push({
          nombre: "Estado proceso compra",
          component: modificarEstadoProceso,
        });
      }
      if (this.tiene_permiso_eliminar_etapas_contrato) {
        tabs.push({
          nombre: "Contratos",
          component: EliminarEtapasContrato,
        });
      }
      if (this.tiene_permiso_eliminar_especificas) {
        tabs.push({
          nombre: "Solicitudes específicas",
          component: ListadoSolicitudesEspecificas,
        });
      }

      // if (this.procesoData?.id_seguimiento_proceso === 90 && this.tiene_permiso_modificar_recurso_revision) {
      if (this.tiene_permiso_modificar_recurso_revision) {
        tabs.push({
          nombre: "Recurso de revisión",
          component: ModificarEtapasRecursoRevision,
        });
      }

      if (this.tiene_permiso_modificar_pip_general) {
        tabs.push({
          nombre: "PIP",
          component: ModificarEtapasPipGeneral,
        });
      }

      if (this.tiene_permiso_modificar_documentos_proceso) {
        tabs.push({
          nombre: "Documentos del proceso",
          component: ModificarDocumentosProceso,
        });
      }

      if (this.esRolConfigurarLotes || this.esRolLecturaConfigurarLotes) {
        tabs.push({
          nombre: "Configuración de Lotes",
          component: ConfiguracionLotesProceso,
        });
      }
      return tabs;
    },
  },
  props: {
    forma_contratacion: {
      type: Number,
      required: true,
    },
  },
};
</script>
