<template>
  <v-card>
    <v-card-title> Listado de Modificativas </v-card-title>
    <v-card-text>
      <v-expansion-panels>
        <v-expansion-panel v-for="(mod, index) in modificativas" :key="index">
          <v-expansion-panel-header>
            {{ mod.TipoModificacionesProceso.nombre }} hecha por: {{ mod.Usuario.email }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- <v-btn
              color="primary"
              class="mb-4"
              :href="`/ruta-base/${mod.adjunto}`"
              target="_blank"
              download
            >
              Ver pdf
            </v-btn> -->
            <v-data-table
              :headers="headers"
              :items="mod.LogModificacionProcesoHumanizadas"
              dense
            >
              <template v-slot:[`item.created_at`]="{ item }">
                {{ moment(item.created_at).format("DD/MM/YYYY HH:mm:ss") }}
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>
<script>
import moment from "moment";

export default {
  name: "ModificacionesProcesoCompra",
  data: () => ({
    modificativas: [],
    headers: [
      { text: "Fecha", value: "created_at" },
      { text: "Descripción", value: "texto" },
    ],
  }),
  methods: {
    async listarModificativas() {
      const { data } =
        await this.services.ModificativasProcesosServices.listarModificativas(
          this.id_proceso
        );
      this.modificativas = data;
    },
  },
  computed: {
    id_proceso() {
      return this?.$route?.params?.idProceso || 0;
    },
  },
  mounted() {
    this.listarModificativas();
  },
};
</script>
